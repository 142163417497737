@import "../common/color.scss";
@import "../common/common.scss";

.transport {
  padding: 50px;
  font-family: "gL";

  h5 {
    text-align: left;
    color: $blue;
    font-family: "g";
    font-size: 17px;
    margin: 7px 0;
  }

  ul {
    background-color: $tabBg;
    border: 1px solid #eee;
    padding: 5px;
    border-radius: 10px;
    justify-content: center;

    li {
      margin: 0px 5px;

      button {
        color: $label;
        font-family: "g";

        &.active {
          margin: 0 !important;
          border-color: #bfcee7 !important;
          background-color: #d2dae8 !important;
          border: 1px solid #bfcee7;
          border-radius: 5px;
          color: $blue !important;
        }
      }
    }
  }

  h2.transport-status {
    font-family: "gBold";
    font-size: 18px;
    width: 70%;
    margin: 0 auto;
    border-radius: 10px;
    padding: 10px;

    &.green {
      color: #62c462;
      background-color: #f2faf5;
    }

    &.red {
      color: #cb493d;
      background-color: #f4eaea;
    }
  }

  h3.assignment-process{
    font-size: 19px;
    margin: 15px 0;
    background: red;
    padding: 15px 0;
    border-radius: 13px;
    color: #FFF;
    font-weight: 900;
  }

  .map {
    margin: 10px 0;
    height: 300px;
    background-color: #cb493d;
  }

  .location {
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;

    .origin-address {
      margin: 20px 0;
    }

    span {
      font-family: "gL";
      font-weight: 700;

      &.title {
        background-color: transparent;
        color: $label;
        font-size: 14px;
        font-weight: 700;
        padding: 0 5px;
      }

      &.icon {
        background-color: transparent;
        padding: 0;

        img {
          width: 20px;
        }
      }

      &.address {
        background-color: transparent;
        padding: 0;
        font-size: 19px;
        color: $title;
      }
    }
  }

  .value {
    font-family: "gL";
    text-align: center;
    margin: 7px 0;

    span.transport-icon {
      width: 25px;
      margin-right: 5px;
    }
  }

  .transport-item-wrapper {
    .value {
      font-weight: 700;
    }

    h5 {
      span.discounted {
        color: red;
        font-size: 12px;
        background-color: antiquewhite;
        padding: 5px;
        border-radius: 9px;
      }

      &.green {
        color: #00cc00;
      }

      &.red {
        color: red;
      }
    }
  }

  .transport-wrapper {
    border-bottom: 1px solid #eee;
    padding: 20px 0;

    .timeline-itam-wrapper {
      .timeline-item-info {
        float: left;
        padding: 40px 0 10px 0px;

        .timeline-transport-info {
          display: flex;
          float: left;
          flex-direction: column;
          align-items: center;

          .timeline-transport-last-step {
            height: 24px;
            margin-top: -24px;
            font-family: "gBold";
            color: #0078f4;
          }

          .timeline-time {
            width: 80px;
            height: 80px;
            border-radius: 40px;
            background-color: #bfcee7;
            border: 1px solid #5682c2;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: "g";
            font-size: 14px;
            margin: 0 auto;
          }

          .timeline-name {
            font-family: "gL";
            font-size: 13px;
            width: 100px;
            display: flex;
            overflow-wrap: anywhere;
          }
        }

        .timeline-line {
          float: left;
          margin-top: 16px;
          margin-left: -10px;
          margin-right: -10px;
          min-width: 90px;
          border-bottom: 1px solid #5682c2;
        }
      }
    }

    .rate {
      .average-value-holder {
        color: $orange;
        font-family: "g";
        font-size: 16px;
        background: #fff;
        width: 80px;
        height: 80px;
        border-radius: 40px;
        border: 1px solid #eee;
        box-shadow: #b5b5b5 0px 0px 5px -3px;
        padding: 28px 0px;
        margin: 0 auto;

        h6 {
          text-align: center;
          font-size: 20px;
        }
      }

      .title {
        margin-bottom: 10px;
      }
    }
  }

  .price {
    border-bottom: 1px solid #eee;
    padding: 20px 0;

    .value {
      font-family: "gBold";
    }
  }

  .transport-user {
    h5 {
      margin-top: 45px;
    }

    border-bottom: 1px solid #eee;
    padding: 20px 0;

    figure {
      width: 80px;
      margin: 0 auto;
      border-radius: 40px;
      overflow: hidden;
      height: 80px;

      img {
        width: 100%;
        object-fit: fill;
      }
    }

    .motor-bike {
      color: $label;
      margin-top: 10px;
      float: right;

      .motor-name {
        width: 149px;
        text-align: center;
      }

      .motor-register-info {
        background-color: $tabBg;
        padding: 5px;
        width: 150px;
        border-radius: 5px;

        .motor-state {
          font-family: "g";
          font-size: 22px;
        }
      }
    }
  }

  .parcel {
    border-bottom: 1px solid #eee;
    padding: 20px 0;

    figure {
      width: 70px;
      margin: 0 auto;

      img {
        width: 100%;
      }
    }

    .parcel-info {
      text-align: right;
    }
  }

  .note {
    border-bottom: 1px solid #eee;
    padding: 20px 0;

    .edit-btn {
      @extend .btn-blue;
      width: 100px;
      height: 35px;
      margin: 0;
      float: right;
      font-size: 13px !important;
    }
  }

  .group-btn {
    ul {
      margin: 20px 0;
      padding: 0;
      background-color: transparent;
      border: 0;

      li {
        display: inline-block;
        margin: 10px;

        button {
          @extend .btn-blue;
          font-weight: 700;
          margin: 0;
          float: right;
          font-size: 13px !important;
          padding: 9px 11px !important;
        }
      }
    }
  }
}

.chat-modal {
  .modal-dialog {
    margin-top: 200px;

    .modal-content {
      max-height: 500px;
      overflow: hidden;

      .modal-body {
        overflow: auto;
      }

      .modal-footer {
        box-shadow: 0px 0px 13px -3px #cac6c6;

        input {
          width: calc(100% - 75px);
          padding: 5px;
          height: 45px;
          border-radius: 10px;
          border: 1px solid #cacaca;
          font-family: "gL";
          text-align: left;
        }

        button {
          font-family: "g";
          width: 55px;
          border: 0;
          height: 45px;
          border-radius: 10px;
          background-color: #efefef;
          &.transport-type{
            width: 100px;
          }
          &:hover {
            background-color: #e1dddd;
          }
        }
      }
    }
  }

  .chat-close {
    border: none;
    background-color: #efefef;
    padding: 10px;
    border-radius: 5px;
  }

  .chat-wrapper {
    margin: 10px 0;

    .user {
      .time {
        font-family: "gl";
        font-size: 13px;

        &.left {
          text-align: left;
        }

        &.right {
          text-align: right;
        }
      }

      p {
        font-family: "g";
        font-size: 13px;
        padding: 10px;
        border-radius: 15px;
        max-width: 70%;
        margin-bottom: 0;
      }

      &.customer {
        img {
          float: left;
        }

        p {
          float: left;
          background-color: #5682c2;
          color: #fff;
          margin-left: 10px;
        }
      }

      &.rider {
        img {
          float: left;
        }

        p {
          float: left;
          background-color: orange;
          color: #fff;
          margin-left: 10px;
        }
      }

      img {
        width: 40px;
        height: 40px;
        border-radius: 20px;
      }

      &.admin {
        img {
          float: right;
        }

        p {
          float: right;
          background-color: green;
          margin-right: 10px;
          color: #fff;
        }
      }
    }
  }

  .clear-fix {
    &::after {
      content: "";
      visibility: hidden;
      display: block;
      height: 0;
      clear: both;
    }

    &::before {
      content: "";
      visibility: hidden;
      display: block;
      height: 0;
      clear: both;
    }
  }
}

.assign-rider-list{
  .modal-dialog{
    max-width: 75% !important;
    .selected-rider-row{
      background-color: #62c462;
    }
  }
}