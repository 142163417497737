.online-bearers {
  text-align: left;
  .search-btn {
    font-weight: 400;
    font-size: 16px;
    border: 2px solid #1f448a !important;
    background-color: #fff;
    padding: 10px 5px;
    border-radius: 25px;
    color: #1f448a;
  }
  .marker-label {
    background-color: #019eff;
    padding: 10px 15px;
    font-size: 14px;
    color: #ffffff !important;
    position: absolute;
    top: 0;
    border-radius: 3px !important;
    z-index: 99999;
  }
}
