@import "../common/color.scss";
@import "../common/common.scss";


.admin-send-email{
    padding: 50px;
    font-family: 'gL';
    .form-group{
        text-align: left;
        label{
            margin: 15px 0;
        }
        input{
            font-weight: 700;
        }
        select{
            font-weight: 700;
        }
    }

    .mg-custom{
        margin: 25px 0;
    }

    .email-send{
        @extend .btn-blue;
        width: 200px;
        height: 50px;
        margin: 50px auto 0;
        font-size: 17px !important;
        font-weight: 700;
    }
}