@import "../common/color.scss";
@import "../common/common.scss";

.search-single {
  .rider-sign-status {
    ul {
      margin: 0;
      padding: 0;
      li {
        display: inline-block;
        &:first-child {
          margin-left: 70px;
          margin-right: 20px;
        }
        button {
          background-color: transparent;
          border: 0;
          img {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }
  h5.status {
    margin-top: 50px;
  }
  .rider-status {
    padding: 20px 0;
    border-bottom: 1px solid #eee;
    span {
      font-size: 12px;
      margin-left: 5px;
      &.online {
        color: green;
      }
      &.offline {
        color: red;
      }
    }

    .transport-type {
      img {
        width: 60px;
        margin-top: 40px;
      }
      .motor-bike {
        color: $label;
        margin-top: 5px;
        .motor-name {
          text-align: center;
        }
        .motor-register-info {
          background-color: $tabBg;
          padding: 5px;
          width: 150px;
          border-radius: 5px;
          margin: 0 auto;
          .motor-state {
            font-family: "g";
            font-size: 22px;
          }
        }
      }
    }
    .map {
      width: 100%;
      height: 110px;
    }

    .status-map-wrapper {
      .latest-activity-warpper {
        margin-top: 20px;
        .value {
          text-align: right;
          font-weight: 700;
          span {
            button {
              height: 45px;
              width: 45px;
              border: none;
              background-color: transparent;
              margin-right: 50px;
              &:hover {
                border: none;
                background-color: transparent;
              }
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .rider-rate-average {
    padding: 20px 0;
    border-bottom: 1px solid #eee;
    .total-rate {
      font-family: "gBold";
      font-size: 19px;
      margin: 0;
      padding: 0;
    }

    .title {
      font-family: "gL";
      text-align: center;
      margin-bottom: 10px;
    }

    .rate {
      .average-value-holder {
        color: $orange;
        font-family: "g";
        font-size: 16px;
        background: #fff;
        width: 80px;
        height: 80px;
        border-radius: 40px;
        border: 1px solid #eee;
        box-shadow: #b5b5b5 0px 0px 5px -3px;
        padding: 28px 0px;
        margin: 0 auto;
        h6 {
          text-align: center;
          font-size: 20px;
        }
      }
    }
  }

  .row-style {
    padding: 20px 0;
    border-bottom: 1px solid #eee;
  }

  .balance {
    .value {
      font-family: "gBold";
      font-size: 19px;
    }
  }
  .rider-ncc {
    .value {
      font-family: "gBold";
      font-size: 19px;
    }
  }

  .check-ncc {
    padding: 20px 0;
    border-bottom: 1px solid #eee;
  }
  .rider-is-on-job {
    padding: 20px 0;
    border-bottom: 1px solid #eee;
    .status {
      margin-top: 5px;
    }
  }
  .motorbike-count {
    padding: 20px 0;
    border-bottom: 1px solid #eee;

    &.allowed-onboarding {
      .deleted {
        margin-top: 5px;
        .reason {
          margin-top: 5px;
          font-size: 14px;
          font-family: "gL";
        }
      }
    }
  }

  .bonus-percentage {
    padding: 20px 0;
    border-bottom: 1px solid #eee;
    .bonus-btn-popups {
      padding: 0;
      margin: 0;
      li {
        height: 35px;
        display: block;
        &:first-child {
          margin-bottom: 10px;
        }
      }
    }
  }
  .rider-road-map {
    padding: 20px 0;
    color: $blue;
    font-weight: 700;
    .step-thumbnail {
      width: 450px;
      margin: 15px auto;
      border: 1px solid $blue;
      position: relative;
      border-radius: 25px;
      &.blur {
        opacity: 0.4;
      }
      .title {
        position: absolute;
        left: -15px;
        background-color: $blue;
        width: 36px;
        height: 36px;
        color: #fff;
        padding: 7px 0;
        border-radius: 18px;
        top: 10px;
      }
      .value {
        text-align: left;
        padding: 10px 0 10px 30px;
      }
      .deleted-btn {
        position: absolute;
        right: 15px;
        top: 12px;
        background-color: transparent;
        padding: 5px 20px;
        color: #c0323c;
        border-radius: 15px;
        font-family: "gL";
        font-size: 13px;
        border: 1px solid #c0323c;
        font-weight: 700;
      }
      .contact-detail {
        border-top: 1px solid $blue;
        .detail {
          display: inline-flex;
          flex-wrap: nowrap;
          align-content: center;
          justify-content: center;
          padding: 10px 39px;
          font-family: "gL";
          font-weight: 700;
          span {
            margin-right: 5px;
          }
          &.blur {
            opacity: 0.3;
          }
          &:first-child {
            border-right: 1px solid $blue;
          }
          &:nth-child(2) {
            border-right: 1px solid $blue;
          }
        }
      }
    }
  }
  .user-section {
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
    .sign-title {
      text-align: center;
      color: #1f448a;
    }
    .sign-value {
      text-align: center;
      margin: 20px auto;
      span.rider-signup-status {
        color: #fff;
        padding: 6px 20px;
        border-radius: 20px;
        font-family: "gL";
        font-size: 13px;
        &.signup-status-purple {
          background-color: purple;
        }
        &.signup-status-approved {
          background-color: #e6f5ec;
          color: #71cf9b;
        }
        &.signup-status-rejected {
          background-color: red;
        }
        &.signup-status-processing {
          background-color: blue;
        }
        &.signup-status-investigation {
          background-color: orange;
        }
      }
    }
  }
}

.bonus-percentage-input-modal {
  font-family: "g";
  font-size: 16px;
  text-align: left;
  color: $title;
  width: 100%;
  height: 45px;
  margin: 50px 0;
  border-radius: 7px;
  border: 1px solid $label;
}

.bonus-modal {
  font-family: "gL";
  input {
    font-weight: 700;
    margin: 10px 0;
  }
  .footer {
    button {
      margin: 15px auto;
      @extend .btn-blue;
      font-size: 23px;
      font-weight: 700;
    }
  }
  textarea {
    height: 80px;
    border-radius: 25px;
    width: 100%;
    padding: 10px;
    font-weight: 700;
    border: 1px solid #ced4da;
  }
}

.signup-documents-review-modal {
  h2 {
    font-size: 17px;
    font-family: "g";
  }
  .documnets {
    a {
      display: flex;
      text-align: center;
      margin: 5px 0;
    }
  }
}

.rider-modal-step {
  font-family: "gL";

  .certificate {
    margin-bottom: 20px;
    img {
      width: 100%;
      min-height: 250px;
    }
  }

  .avatar-image {
    width: 150px;
    height: 150px;
    overflow: hidden;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }

  .modal-header {
    padding: 10px 20px;
  }
  .title {
    text-align: left;
    font-size: 14px;
  }

  label {
    text-align: left;
    font-size: 14px;
  }
  input {
    font-weight: 700;
  }

  .form-group {
    input {
      height: 45px;
      border-radius: 15px;
    }
  }
  .first-step-name {
    input {
      font-weight: 700;
      height: 45px;
      border-radius: 15px;
    }
  }

  .first-step-address {
    padding: 10px 0;
    label {
      margin-left: 5px;
    }
  }

  .first-step-insurance {
    label {
      margin-left: 5px;
    }
  }

  .policy-number {
    padding: 10px 0;
    label {
      text-align: left;
      font-size: 14px;
    }
    input {
      height: 45px;
      border-radius: 15px;
    }
  }

  .group-btn-verify {
    text-align: center;
    margin: 20px 0;

    button {
      @extend .btn-blue;
      font-weight: 700;
      width: 100%;
      height: 46px;
      &:disabled {
        opacity: 0.4;
      }
    }
  }
  .group-btn-reject {
    button {
      @extend .btn-blue;
      font-weight: 700;
      margin-top: 10px;
      height: 46px;
      &:disabled {
        opacity: 0.4;
      }
      &:first-child {
        width: 100px;
        margin-right: 5px;
      }
      &:nth-child(2) {
        width: calc(100% - 105px);
      }
    }
  }
  textarea {
    height: 80px;
    border-radius: 25px;
    width: 100%;
    padding: 10px;
    font-weight: 700;
    border: 1px solid #ced4da;
  }
  .modal-footer {
    border: none;
  }

  .mg-btm {
    margin-bottom: 10px;
  }

  .residency-status {
    label {
      margin-left: 5px;
    }
  }

  .working-condition {
    label {
      margin-left: 5px;
    }
  }

  iframe {
    width: 100%;
    height: 100%;
  }
}

.workAreaVisaConditionTextArea {
  position: relative;
  .custom-error {
    position: absolute;
    bottom: 0;
    font-size: 12px;
    padding: 5px;
    text-align: center;
    margin: 0 auto;
    width: 80%;
    left: 0;
    right: 0;
    border-radius: 25px;
  }
}

.pdf-viewer {
  height: 100%;
}

.rider-ncc-green {
  padding: 5px 15px;
  border-radius: 15px;
  margin-top: -2px;
  display: block;
  color: #fff;
  background-color: #62c462 !important;
}
.rider-ncc-red {
  padding: 5px 15px;
  border-radius: 15px;
  color: #fff;
  margin-top: -2px;
  display: block;
  background-color: #cb493d !important;
}

.online-time-calculator-time-selector {
  .modal-dialog {
    margin-top: 150px;
  }
  .modal-footer {
    button {
      margin-top: 10px !important;
    }
  }
}

.online-time-calculator-time-show {
  padding-top: 25px;
  .bearer-name {
    text-align: left;
  }
  .selected-time {
    text-align: right;
  }
  .calculator-time-show-title {
    font-size: 16px;
    padding: 20px 10px;
    span {
      font-size: 19px;
      padding-bottom: 5px;
      border-bottom: 2px solid $backgroundBlue;
    }
  }

  .search-list {
    padding: 10px !important;
    table {
      z-index: 99;
      td {
        position: relative;
        padding: 5px !important;
        z-index: 99;
        &.has-time {
          cursor: pointer;
        }
        &.bg-color-0 {
          background-color: #fff;
        }
        &.bg-color-1 {
          background-color: #e0ebf6;
        }
        &.bg-color-2 {
          background-color: #c2d6ec;
        }
        &.bg-color-3 {
          background-color: #a3c1e3;
        }
        &.bg-color-4 {
          background-color: #6a99d0;
        }
        &.bg-color-5 {
          background-color: #4273b0;
          color: #fff;
        }
        &.bg-color-6 {
          background-color: #2c4d75;
          color: #fff;
        }
      }
    }
  }
}
