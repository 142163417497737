@import "../common/color.scss";
@import "../common/common.scss";

.coupons{
    .header{
        position: relative;
    }
    span.add-new-coupons{
        position: absolute;
        top: 15px;
        right: 0;
        a{
            color: $blue;
            border: 2px solid $blue;
            background-color: transparent;
            padding: 10px 20px;
            font-family: "g";
            font-size: 15px;
            border-radius: 17px;
            text-decoration: none;
        }
    }
}
.coupons-single{
    font-family: "gL";
    padding: 50px;
    .form-group{
        text-align: left;
        label{
            margin: 20px 0 10px;
        }
    }

    input{
        font-family: 'gL';
        font-weight: 700;
    }

    select {
        font-family: "gL";
        font-weight: 700;
    }

    .coupon-action-btn{
        margin: 0;
        padding: 0;
        li{
            margin: 0 50px 50px 0;
            display: inline-block;
            button{
                color: $blue !important;
                border: 1px solid $blue;
                background-color: transparent !important;
                padding: 2px 48px;
                min-width: 200px;
                font-family: "g";
                font-size: 16px;
                border-radius: 25px !important;
                text-decoration: none !important;
            }
        }
    }


}

.top-buffer { margin-top:20px; }