.web-enquiries {
  * {
    font-family: "g";
  }
  .web-content {
    .sider-bar {
      background: #fbfbfb;
      padding: 10px;
      width: 350px;
      position: fixed;
      overflow-y: auto;
      top: 0;
      left: 0;
      bottom: 0;

      .selected-card {
        background-color: #e2e2e2;
      }
      .ant-card {
        cursor: pointer;
        overflow: hidden;
        .ant-card-head {
          background: transparent;
          border: none;
          .ant-card-head-title {
            text-align: left;
          }
        }
        .ant-card-body {
          padding-top: 0;
          .ant-space {
            display: block;
            text-align: left;
          }
          .message-body {
            width: 100%;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .assign-wrapper {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 0;
            height: 0;
            border-right: 50px solid #019eff;
            border-top: 50px solid #bbb0;
            .assign-to-self {
              position: absolute;
              right: -41px;
              bottom: 8px;
            }
          }
        }
      }
      .inbox-segment {
        margin-left: 5px;
        .ant-segmented-item {
          min-width: 165px;
        }
      }
    }
    .content {
      margin-left: 350px;
      min-height: calc(100vh - 200px);
      padding: 0 50px;
      right: 0;
      overflow: scroll;
      position: fixed;
      overflow-y: auto;
      top: 0;
      left: 0;
      bottom: 0;
      .case-status {
        padding: 10px 15px;
        border-radius: 15px;
        color: #fff;
      }
      .modify-date {
        padding: 10px 15px;
        border-radius: 15px;
        border: 2px solid #cdcdcd;
      }
      .message-body {
        font-family: "gL";
        line-height: 25px;
        font-weight: 600;
        font-size: 15px;
      }
    }
  }
}
