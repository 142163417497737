@import "../common/color.scss";
@import "../common/common.scss";
.parcel-list {
  padding: 50px;

  table {
    border-radius: 10px;
    box-shadow: $tabBg 0px 0px 0px 1px;
    overflow: hidden;
    & > :not(:first-child) {
      border-top: 0;
    }
    thead {
      background-color: $tabBg;
      font-family: "gL";
      font-size: 14px;
      color: #9e9e9e;
      img {
        width: 30px;
      }
    }
    tbody {
      border: 0;
      tr {
        border-bottom: 1px solid $tabBg;
        font-weight: 700;
        td {
          font-family: "gL";
          border: 0;
          color: #4b5056;
          font-size: 15px;
          padding: 20px 0;
        }
      }

      .search-view {
        @extend .btn-blue;
        &.delete {
          color: #c0323c !important;
          border: 1px solid #c0323c !important;
        }
      }
    }
  }

  ul {
    padding: 0;
    margin: 0;
    li {
      display: inline-block;
      margin: 20px;
      .action-button {
        @extend .btn-blue;
        min-width: 180px;
        height: 45px;
        font-weight: 600;
        margin-top: 30px;
      }
    }
  }
}

.parcel-single {
  padding: 50px;
  font-family: "gL";
  .form-group {
    text-align: left;
    margin-bottom: 20px;
    label {
      margin: 20px 0 10px;
    }
  }

  input {
    font-family: "gL";
    font-weight: 700;
  }

  .title {
    text-align: left;
    img {
      width: 44px;
      margin-right: 5px;
    }
  }

  .radio-selection {
    border-top: 1px solid #eee;
    padding: 20px 0;
    .title {
      margin-bottom: 15px;
      height: 30px;
    }
    .radio-input {
      text-align: left;
      &.is-shown-for-order {
        .title-span {
          margin-right: 50px;
        }
        label {
          margin-right: 50px;
        }
      }
    }

    input {
      margin-right: 5px;
    }
    img.parcel-image {
      margin-top: 64px;
      max-width: 50px;
      height: 40px;
    }
  }

  .save-parcel {
    @extend .btn-blue;
    min-width: 180px;
    height: 45px;
    font-weight: 600;
    margin-top: 30px;
  }
}
