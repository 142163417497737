@import "../common/color.scss";
@import "../common/common.scss";
.pricing-object {
  padding: 50px;
  font-family: "gL";
  .title {
    text-align: left;
    color: $blue;
    font-family: "g";
    font-size: 17px;
    margin: 40px 0 15px 0;
  }
  .form-group {
    text-align: left;
    label {
      margin-bottom: 10px;
    }
    input {
      font-weight: 700;
    }
  }

  .btn {
    color: $blue;
    border: 1px solid $blue;
    background-color: transparent !important;
    padding: 3px 20px;
    font-family: "g";
    font-size: 22px;
    border-radius: 15px;
    text-decoration: none;
    width: 200px;
    margin: 90px auto 20px;
  }
  ul {
    padding: 0;
    margin: 0;
    li {
      display: inline-block;
      margin: 20px;
    }
  }
}
