.batch-rider-bonus-update {
  text-align: left;

  .batch-update {
    width: 200px;
    height: 50px;
    margin: 50px auto 0;
    font-size: 17px !important;
    font-weight: 700;
  }
}
