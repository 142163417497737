@import "../common/color.scss";
@import "../common/common.scss";

.search {
  margin: 50px auto;
  max-width: 400px;
  font-family: "g";
  text-align: left;
  .form-group {
    margin-top: 20px;
    label {
      color: $label;
      font-size: 15px;
    }
  }
  .btn {
    margin-top: 70px !important;
  }
  h2 {
    text-align: center;
    color: $title;
    margin-bottom: 100px;
  }
}

.search-list {
  padding: 20px 50px;
  font-family: "g";
  h6 {
    color: $label;
    padding: 10px 0;
    font-size: 15px;
  }
  table {
    border-radius: 10px;
    box-shadow: $tabBg 0px 0px 0px 1px;
    overflow: hidden;
    & > :not(:first-child) {
      border-top: 0;
    }
    thead {
      background-color: $tabBg;
      font-family: "gL";
      font-size: 14px;
      color: #9e9e9e;
    }

    tbody {
      border: 0;
      tr {
        border-bottom: 1px solid $tabBg;
        font-weight: 700;
        td {
          font-family: "gL";
          border: 0;
          color: #4b5056;
          font-size: 15px;
          padding: 20px 0;
          span.rider-signup-status {
            color: #fff;
            padding: 6px 20px;
            border-radius: 20px;
            font-family: "gL";
            font-size: 13px;
            &.signup-status-purple {
              background-color: purple;
            }
            &.signup-status-approved {
              background-color: #e6f5ec;
              color: #71cf9b;
            }
            &.signup-status-rejected {
              background-color: red;
            }
            &.signup-status-processing {
              background-color: blue;
            }
            &.signup-status-investigation {
              background-color: orange;
            }
          }
        }
      }

      .search-view {
        @extend .btn-blue;
      }
    }
  }
}

.search-single {
  font-family: "g";
  padding: 50px;
  h5 {
    text-align: left;
    color: $blue;
    font-family: "g";
    font-size: 17px;
    margin: 7px 0;
  }
  .value {
    font-family: "gL";
    text-align: center;
    margin: 7px 0;
  }

  .user-info {
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
    .profile-avatar {
      margin-bottom: 30px;
      figure {
        width: 120px;
        height: 120px;
        overflow: hidden;
        margin: 20px auto;
        border: 8px solid $tabBg;
        box-shadow: #625d5d 0px 0px 4px 0px;
        border-radius: 60px;
        .avatar {
          width: 100%;
          img {
            width: 100%;
            min-height: 100%;
            object-fit: cover;
          }
        }
      }
      figcaption {
        margin: 0 auto;
        padding-right: 35px;
        color: $label;
        font-size: 19px;

        span {
          img {
            width: 25px;
            margin: 3px 3px 10px 10px;
          }
        }
      }
    }
    .thumbnail {
      max-width: 200px;
      margin: 0 auto;
      span {
        img {
          width: 27px;
        }
      }
      h6 {
        text-align: center;
        color: $label;
        font-family: "gL";
        font-weight: 700;
        margin-top: 15px;
        font-size: 17px;
      }
    }

    .rider-profile-location {
      h6 {
        text-align: center;
        color: #59636f;
        font-family: "gL";
        font-weight: 700;
        margin-top: 15px;
        font-size: 17px;
        span {
          margin-right: 10px;
          img {
            width: 24px;
          }
        }
      }
    }
    .user-extra-info {
      border-top: 1px solid #eee;
      margin-top: 24px;
      padding-top: 20px;
      .title-extra {
        margin: 20px 0;
        color: $blue;
        font-family: "g";
        span {
          margin-left: 5px;
          color: $label;
          font-family: "gL";
          font-weight: 700;
        }
      }
    }
  }

  .user-bearer-info {
    padding: 20px 0;
    border-bottom: 1px solid #eee;
    .thumbnail {
      // width: 150px;
      height: 70px;
      background-color: $backgroundBlue;
      padding: 13px 5px;
      border-radius: 16px;
      margin: 20px auto;
      color: #fff;
      font-family: "g";
      .title {
        font-family: "gL";
        font-weight: 700;
      }
      .value {
        font-family: "gBold";
        margin: 0;
      }
    }
  }

  .note {
    padding: 20px 0;
    border-bottom: 1px solid #eee;
    ul {
      padding: 0;
      margin: 0;
      li {
        display: block;
        height: 35px;
        &:first-child {
          margin-bottom: 10px;
        }
      }
    }
  }

  .latest-activity {
    padding: 20px 0;
    border-bottom: 1px solid #eee;
  }

  .allowed-by-bearer {
    padding: 20px 0;
    border-bottom: 1px solid #eee;

    .reason {
      font-family: "gL";
      margin: 5px 0;
    }
  }

  .crm-tag {
    padding: 20px 0;
    border-bottom: 1px solid #eee;
  }

  .loyalty {
    padding: 20px 0;
    border-bottom: 1px solid #eee;
  }

  .order-limit {
    padding: 20px 0;
    border-bottom: 1px solid #eee;
  }
  .edit-btn {
    @extend .btn-blue;
    width: 100px;
    height: 35px;
    margin: 0;
    float: right;
    font-size: 13px !important;
  }

  .group-btn {
    ul {
      margin: 20px 0;
      padding: 0;
      li {
        display: inline-block;
        margin: 10px;
        button {
          @extend .btn-blue;
          font-weight: 700;
          margin: 0;
          float: right;
          font-size: 13px !important;
          padding: 9px 11px !important;
          &:disabled {
            opacity: 0.4;
          }
        }

        a {
          @extend .btn-blue;
          font-weight: 700;
          margin: 0;
          float: right;
          font-size: 13px !important;
          padding: 9px 11px !important;
        }
      }
    }
  }
}

.note-modal {
  .note-text {
    width: 100%;
    padding: 20px;
    height: 280px;
    text-align: left;
    border: 1px solid #c6c4c4;
    resize: none;
    font-size: 20px;
    font-family: "gL";
    font-weight: 700;
    color: $title;
    border-radius: 10px;
  }

  .btn {
    @extend .btn-blue;
    font-size: 18px !important;
    font-weight: 700;
    margin: 10px 0;
  }
}

.allowed-by-user {
  textarea.allowed-by-user-textArea {
    width: 100%;
    padding: 20px;
    height: 280px;
    text-align: left;
    border: 1px solid #c6c4c4;
    resize: none;
    font-size: 20px;
    font-family: "gL";
    font-weight: 700;
    color: $title;
    border-radius: 10px;
    margin: 20px 0;
  }

  .btn {
    @extend .btn-blue;
    font-size: 18px !important;
    font-weight: 700;
    margin: 10px 0;
  }
}

*:focus {
  outline: 0;
}

.toggleSwitch {
  .container {
    text-align: right;

    .switch-label {
      margin: 10px 0;
      float: left;
    }
  }
  .toggle-switch {
    position: relative;
    width: 75px;
    display: inline-block;
    text-align: left;
    top: 8px;
  }
  .checkbox {
    display: none;
  }
  .label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
  }
  .inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
  }
  .inner:before,
  .inner:after {
    float: left;
    width: 50%;
    height: 36px;
    padding: 0;
    line-height: 36px;
    color: #fff;
    font-weight: bold;
    box-sizing: border-box;
  }
  .inner:before {
    content: "YES";
    padding-left: 10px;
    background-color: #060;
    color: #fff;
    text-align: left;
  }
  .inner:after {
    content: "NO";
    padding-right: 10px;
    background-color: #bbb;
    color: #fff;

    text-align: right;
  }
  .switch {
    display: block;
    width: 24px;
    margin: 5px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 40px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }
  .checkbox:checked + .label .inner {
    margin-left: 0;
  }
  .checkbox:checked + .label .switch {
    right: 0px;
  }
}

.latest-activity-modal {
  .modal-body {
    p {
      text-align: center;
      font-size: 20px;
      color: $title;
    }
    h6 {
      text-align: center;
      font-size: 25px;
    }
  }

  .modal-footer {
    button {
      margin: 15px auto;
      @extend .btn-blue;
      font-size: 23px;
      font-weight: 700;
    }
  }
}

.modal-popup-custom {
  font-family: "gL";
  .modal-body {
    .form-group {
      margin-bottom: 10px;
      label {
        margin: 10px 0;
      }
      select {
        font-weight: 700;
      }
      input {
        font-weight: 700;
      }
    }
  }
  .modal-footer {
    button {
      margin: 15px auto;
      @extend .btn-blue;
      font-size: 23px;
      font-weight: 700;
    }
  }
}
.note-history-modal {
  .modal-dialog {
    max-width: 50% !important;
    .search-view {
      height: 35px;
      margin: 0 auto;
      width: 100px;
    }
  }
}

.note-history-detail-modal {
  .modal-dialog {
    margin-top: 100px;
  }
}
