$blue: #1f448a;
$line: #d9d9d9;
$fontGrayBlue: #59636d;
$activeBlue: #019eff;
$backgroundBlue: #409ef8;
$orange: #f19043;
$lightGreen: hsla(120, 100%, 25%, 0.739);
$label: #59636f;
$title: #4b5056;
$tabBg: #f2f4f8;
$lightBlue: #0000ff1f;
$lightOrange: #ffa5002e;
$lightPurple: #80008026;
$lightGray: #80808026;
$lightRed: #ff00001c;
$lightRedPink: #ffedfd;
