@import "../common/color.scss";
@import "../common/common.scss";

.show-all-list {
  padding: 50px;
  font-family: "g";

  h6 {
    color: $label;
    padding: 10px 0;
    font-size: 15px;
  }

  table {
    border-radius: 10px;
    box-shadow: $tabBg 0px 0px 0px 1px;
    overflow: hidden;

    & > :not(:first-child) {
      border-top: 0;
    }

    thead {
      background-color: $tabBg;
      font-family: "gL";
      font-size: 14px;
      color: #9e9e9e;
    }

    tbody {
      border: 0;

      tr {
        border-bottom: 1px solid $tabBg;
        font-weight: 700;

        td {
          font-family: "gL";
          border: 0;
          color: #4b5056;
          font-size: 15px;
          padding: 20px 0;
        }
      }
    }

    .single-view {
      margin: 0 20px;
      @extend .btn-blue;
    }

    .bearer {
      img {
        width: 34px;
        margin-right: 7px;
      }
    }
  }

  .load-more {
    button {
      @extend .btn-blue;
      font-weight: 700;
      font-size: 13px !important;
      padding: 9px 11px !important;
      width: 200px;
      margin: 30px auto;
    }
  }
}

.modes-of-transport {
  padding: 50px;
  font-family: "g";

  .transport-modes {
    border-bottom: 1px solid $tabBg;
    padding: 20px;
  }

  .title {
    text-align: left;
    color: $blue;
    font-family: "g";
    font-size: 17px;
    margin: 7px 0;

    span {
      margin-right: 10px;

      img {
        &.cycling {
          margin-top: -12px;
        }
      }
    }
  }

  .btn-blue {
    width: 100px;
    height: 35px;
    margin: 0;
    float: right;
    font-size: 13px !important;
  }

  .status {
    margin-top: 8px;
  }

  .riding {
    padding: 20px;

    .title {
      padding-left: 15px;
    }

    table {
      border-radius: 10px;
      box-shadow: $tabBg 0px 0px 0px 1px;
      overflow: hidden;

      & > :not(:first-child) {
        border-top: 0;
      }

      thead {
        background-color: $tabBg;
        font-family: "gL";
        font-size: 14px;
        color: #9e9e9e;
      }

      tbody {
        border: 0;

        tr {
          border-bottom: 1px solid $tabBg;
          font-weight: 700;

          td {
            font-family: "gL";
            border: 0;
            color: #4b5056;
            font-size: 15px;
            padding: 20px 0;

            .status-custom {
              margin: 0;
            }

            &.custom-td {
              padding: 14px 0;
            }
          }
        }

        .search-view {
          @extend .btn-blue;
        }
      }

      .single-view {
        margin: 0 20px;
        @extend .btn-blue;
        padding: 7px 20px !important;
      }
    }
  }
}

.motobike-status-modes-transport {
  .status-custom-modes {
    span {
      padding: 6px 20px;
      border-radius: 20px;
      font-family: "g";
      font-size: 13px;
    }

    &.processing {
      span {
        background-color: $lightBlue;
        color: blue;
      }
    }

    &.approved-valid {
      span {
        background-color: $lightGreen;
        color: #fff;
        opacity: 0.75;
      }
    }
    &.approved-invalid {
      span {
        background-color: $lightRedPink;
        color: black;
        opacity: 0.7;
      }
    }

    &.expired {
      span {
        background-color: $lightPurple;
        color: purple;
      }
    }

    &.investigation {
      span {
        background-color: $lightOrange;
        color: orange;
      }
    }

    &.restricted {
      span {
        background-color: $lightGray;
        color: gray;
      }
    }

    &.rejected {
      span {
        background-color: $lightRed;
        color: red;
      }
    }

    &.removed {
      span {
        background-color: red;
        color: #fff;
      }
    }
  }
}
