@import "../common/color.scss";
@import "../common/common.scss";
.sidebar {
  min-height: 100vh;
  background-color: rgb(251 251 251);
  -webkit-box-shadow: 5px 0px 6px -1px #808080;
  box-shadow: -1px 0px 6px -1px #808080;
  font-family: "g";
  color: $fontGrayBlue;
  min-height: 100vh;
  width: 320px;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1020;
  overflow: auto;
  .navbar {
    padding: 0px !important;
    figure {
      width: 100px;
      height: 100px;
      overflow: hidden;
      border-radius: 50%;
      img {
        object-fit: cover;
        width: 100%;
        min-height: 100%;
      }
    }
    .userInfo {
      padding: 50px 25px 20px;
      figure {
        -webkit-box-shadow: 0px 0px 9px 0px #808080;
        box-shadow: 0px 0px 9px 0px #808080;
        float: left;
      }
      figcaption {
        float: left;
        margin-top: 23px;
        margin-left: 10px;
        max-width: 134px;
        .userName {
          font-family: "gBold";
          text-align: left;
          font-size: 13px;
        }
        .userLevel {
          text-align: left;
          font-size: 13px;
        }
      }
    }
    .line {
      width: calc(100% - 50px);
      height: 1px;
      background-color: #d9d9d9;
      margin: 0 auto;
    }
    ul {
      li {
        display: flex;
        margin: 20px 0 0 16px;
        a {
          transition: all 0.3s ease-out;
          text-decoration: none;
          color: $fontGrayBlue;
          margin: 0;
          text-align: left;
          &.dashboardIcon::before {
            @extend .sidebarMenuIcon;
            background: url("../../assets/dashboard.svg");
          }

          &.customerIcon::before {
            @extend .sidebarMenuIcon;
            background: url("../../assets/customer.svg");
          }

          &.allCustomer::before {
            @extend .sidebarMenuIcon;
            background: url("../../assets/allCustomer.svg");
          }

          &.riderIcon::before {
            @extend .sidebarMenuIcon;
            background: url("../../assets/rider.svg");
          }

          &.verify-bearers::before {
            @extend .sidebarMenuIcon;
            background: url("../../assets/verify-bearers.svg");
          }

          &.bearer-list::before {
            @extend .sidebarMenuIcon;
            background: url("../../assets/bearer-list.svg");
          }

          &.onlineBearerIcon::before {
            @extend .sidebarMenuIcon;
            background: url("../../assets/online-bearer.svg");
          }

          &.transportIcon::before {
            @extend .sidebarMenuIcon;
            background: url("../../assets/transport.svg");
          }

          &.incomplete-assignments::before {
            @extend .sidebarMenuIcon;
            background: url("../../assets/incomplete-assignments.svg");
          }

          &.batch-rider-bonus-update::before {
            @extend .sidebarMenuIcon;
            background: url("../../assets/batchRiderBonusUpdate.svg");
          }

          &.auto-assigned-transport-list::before {
            @extend .sidebarMenuIcon;
            background: url("../../assets/autoAssignedTransportList.svg");
          }

          &.motorbikeIcon::before {
            @extend .sidebarMenuIcon;
            background: url("../../assets/motorbike.svg");
          }

          &.motorbike-list-icon::before {
            @extend .sidebarMenuIcon;
            background: url("../../assets/motorbikList.svg");
          }

          &.ncc-document-verification::before {
            @extend .sidebarMenuIcon;
            background: url("../../assets/ncc.svg");
          }

          &.couponsIcon::before {
            @extend .sidebarMenuIcon;
            background: url("../../assets/coupon.svg");
          }

          &.remoteConfigIcon::before {
            @extend .sidebarMenuIcon;
            background: url("../../assets/remoteConfig.svg");
          }

          &.priceIcon::before {
            @extend .sidebarMenuIcon;
            background: url("../../assets/pricingObject.svg");
          }

          &.parcelIcon::before {
            @extend .sidebarMenuIcon;
            background: url("../../assets/parcel.svg");
          }

          &.pushIcon::before {
            @extend .sidebarMenuIcon;
            background: url("../../assets/pushNotification.svg");
          }

          &.businessCustomerIcon::before {
            @extend .sidebarMenuIcon;
            background: url("../../assets/businessCustomer.svg");
          }

          &.sendSMSIcon::before {
            @extend .sidebarMenuIcon;
            background: url("../../assets/sendSMS.svg");
          }

          &.sendEmailIcon::before {
            @extend .sidebarMenuIcon;
            background: url("../../assets/sendEmail.svg");
          }

          &.webEnquiries::before {
            @extend .sidebarMenuIcon;
            background: url("../../assets/web-enquiries.svg");
          }

          &.active,
          &:hover {
            color: $activeBlue;
            &.dashboardIcon::before {
              @extend .sidebarMenuIcon;
              background: url("../../assets/dashboard-selected.svg");
            }

            &.customerIcon::before {
              @extend .sidebarMenuIcon;
              background: url("../../assets/customer-selected.svg");
            }

            &.allCustomer::before {
              @extend .sidebarMenuIcon;
              background: url("../../assets/allCustomer-selected.svg");
            }

            &.riderIcon::before {
              @extend .sidebarMenuIcon;
              background: url("../../assets/rider-selected.svg");
            }

            &.verify-bearers::before {
              @extend .sidebarMenuIcon;
              background: url("../../assets/verify-bearers-selected.svg");
            }

            &.bearer-list::before {
              @extend .sidebarMenuIcon;
              background: url("../../assets/bearer-list-selected.svg");
            }

            &.onlineBearerIcon::before {
              @extend .sidebarMenuIcon;
              background: url("../../assets/online-bearer-selected.svg");
            }

            &.transportIcon::before {
              @extend .sidebarMenuIcon;
              background: url("../../assets/transport-selected.svg");
            }

            &.incomplete-assignments::before {
              @extend .sidebarMenuIcon;
              background: url("../../assets/incomplete-assignments-selected.svg");
            }

            &.batch-rider-bonus-update::before {
              @extend .sidebarMenuIcon;
              background: url("../../assets/batchRiderBonusUpdate-selected.svg");
            }

            &.auto-assigned-transport-list::before {
              @extend .sidebarMenuIcon;
              background: url("../../assets/autoAssignedTransportList-selected.svg");
            }

            &.motorbikeIcon::before {
              @extend .sidebarMenuIcon;
              background: url("../../assets/motorbike-selected.svg");
            }

            &.motorbike-list-icon::before {
              @extend .sidebarMenuIcon;
              background: url("../../assets/motorbikList-selected.svg");
            }
            &.ncc-document-verification::before {
              @extend .sidebarMenuIcon;
              background: url("../../assets/ncc-selected.svg");
            }

            &.couponsIcon::before {
              @extend .sidebarMenuIcon;
              background: url("../../assets/coupon-selected.svg");
            }

            &.remoteConfigIcon::before {
              @extend .sidebarMenuIcon;
              background: url("../../assets/remoteConfig-selected.svg");
            }

            &.priceIcon::before {
              @extend .sidebarMenuIcon;
              background: url("../../assets/pricingObject-selected.svg");
            }

            &.parcelIcon::before {
              @extend .sidebarMenuIcon;
              background: url("../../assets/parcel-selected.svg");
            }

            &.pushIcon::before {
              @extend .sidebarMenuIcon;
              background: url("../../assets/pushNotification-selected.svg");
            }

            &.businessCustomerIcon::before {
              @extend .sidebarMenuIcon;
              background: url("../../assets/businessCustomer-selected.svg");
            }

            &.sendSMSIcon::before {
              @extend .sidebarMenuIcon;
              background: url("../../assets/sendSMS-selected.svg");
            }

            &.sendEmailIcon::before {
              @extend .sidebarMenuIcon;
              background: url("../../assets/sendEmail-selected.svg");
            }

            &.webEnquiries::before {
              @extend .sidebarMenuIcon;
              background: url("../../assets/web-enquiries-selected.svg");
            }
          }
        }
      }
    }
  }
}

.topbar {
  font-family: "gBold";
  font-size: 28px;
  color: $fontGrayBlue;
  padding: 0px 50px;
  margin-top: 55px;

  .info {
    text-align: left;
    .logout {
      float: right;
      button {
        color: $fontGrayBlue;
        text-decoration: none;
        font-size: 20px;
        background-color: transparent;
        border: none;
      }
    }
  }
  .line {
    width: 100%;
    height: 1px;
    background-color: $line;
  }
}

.main-data-section {
  width: calc(100% - 320px);
  margin-left: 320px;
}

.sidebarMenuIcon {
  transition: all 0.3s ease-out;
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  background-position: center center;
  background-repeat: no-repeat;
  left: 17px;
  background-size: contain;
}

.rider-modal-step {
  .modal-dialog {
    max-width: calc(100% - 200px) !important;
  }
}
.count-down-session {
  position: absolute;
  right: 20px;
  // display: none;
  span {
    font-family: "g";
    font-size: 14px;
  }
}

.data-server-list {
  .search-view {
    @extend .btn-blue;
    font-weight: 900;
    font-size: 16px;
    border: 2px solid $blue !important;
  }

  .engagement-note {
    span {
      padding: 6px 15px;
      border-radius: 20px;
      font-family: "g";
      font-size: 13px;
    }
    &.no-interest {
      span {
        background-color: red;
        color: #fff;
      }
    }
    &.slight-interest {
      span {
        color: red;
        background-color: rgba(255, 0, 0, 0.095);
      }
    }
    &.moderate-interest {
      span {
        background-color: $lightOrange;
        color: orange;
      }
    }
    &.interested {
      span {
        background-color: $lightBlue;
        color: blue;
      }
    }
    &.very-interested {
      span {
        background-color: #e6f5ec;
        color: #71cf9b;
        opacity: 0.75;
      }
    }
    &.extremely-interested {
      span {
        background-color: $lightGreen;
        color: #fff;
        opacity: 0.75;
      }
    }
  }
  .rider-stage {
    background-color: #ffa500;
    display: block;
    border-radius: 15px;
    color: #fff;
    font-weight: bold;
    font-family: "gBold";
    padding: 3px 0 0;
    width: 30px;
    margin: 0 auto;
    height: 30px;
  }
}
