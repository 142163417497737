@import "../common/color.scss";
@import "../common/common.scss";

.ncc-verification {
  .pdf-show {
    width: 100%;
    min-height: 100vh;
    iframe {
      width: 100%;
      min-height: 100vh;
    }
  }

  .web-view-side-show {
    width: 100%;
    min-height: 40vh;

    iframe {
      width: 100%;
      min-height: 40vh;
    }

    .form-group {
      text-align: left;
      margin-top: 20px;
    }

    .radio-ncc {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: space-evenly;
      margin-top: 60px;
    }
  }

  .form-group {
    text-align: left;
    margin-top: 20px;
    .textarea-info {
      width: 100%;
      padding: 10px;
      height: 200px;
      resize: none;
      border-radius: 5px;
    }
  }

  .radio-ncc-reason {
    input {
      margin-top: 10px;
    }
    label {
      font-size: 15px;
    }
  }

  .ncc-group-btn-reason {
    button {
      @extend .btn-blue;
      font-weight: 700;
      margin: 40px 0 20px;
      height: 46px;
      min-width: 150px;
      &:disabled {
        opacity: 0.4;
      }
    }
  }

  .ncc-riviewed {
    background: #f67d89;
    width: 100px;
    margin: 0 auto;
    padding: 2px;
    border-radius: 15px;
    color: #fff;
    font-size: 15px;
  }
  .ncc-verified {
    background: #71cf9a;
    width: 100px;
    margin: 0 auto;
    padding: 2px;
    border-radius: 15px;
    color: #fff;
    font-size: 15px;
  }
}
