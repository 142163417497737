
@import "../common/color.scss";

.dashboard{
    .rider{
        background-color: $orange;
        @extend .dashboard-report-thumbnail;
    }

    .customer{
        background-color: $backgroundBlue;
        @extend .dashboard-report-thumbnail;
    }

    .business-customer{
        background-color: $lightGreen;
        @extend .dashboard-report-thumbnail;
    }

    .box-report{
        span{
            &.title{
                float: left;
            }
            &.value{
                float: right;
            }
        }
    }
}

.dashboard-report-thumbnail{
    font-family: "gBold";
    width: 90%;
    height: 80px;
    margin: 50px auto;
    box-shadow: 0px 0px 6px 0px #8f8f8f;
    border-radius: 20px;
    padding: 22px 10px;
    font-size: 24px;
    color: #FFF;
}