@import "./color.scss";

.spinner-outer-block {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0000008f;
  z-index: 999999;
  .spinner-block {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.container-fluid {
  padding-left: 0 !important;
}

.active {
  margin: 7px 0;
  &.mar0 {
    margin: 0 !important;
  }

  span {
    background-color: #e6f5ec;
    padding: 6px 20px;
    color: #71cf9a;
    border-radius: 20px;
    font-family: "gL";
    font-size: 13px;
  }
}

.deleted {
  span {
    background-color: $lightRed;
    padding: 6px 20px;
    color: red;
    border-radius: 20px;
  }
}

.rider-deleted {
  span {
    background-color: $lightBlue;
    padding: 6px 20px;
    color: blue;
    border-radius: 20px;
  }
}

.spinner-border {
  border: 0.25em solid #4c5462;
  border-right-color: white !important;
}

.margin-top-bottom {
  margin: 20px 0;
}

h5.admin-header-info {
  text-align: left;
  color: $blue;
  font-family: "g";
  font-size: 17px;
  margin: 7px 0;
}
.admin-header-info-value {
  font-family: "gL";
  text-align: center;
  margin: 7px 0;
  font-weight: 700;
}
.admin-info-components {
  border-bottom: 1px solid #eee;
  padding: 20px 0;
}

.admin-header-info-value-true {
  margin: 4px 0;
  span {
    background-color: #e6f5ec;
    padding: 6px 20px;
    color: #71cf9a;
    border-radius: 20px;
    font-family: "g";
    font-size: 13px;
  }
}

.admin-header-info-value-false {
  margin: 4px 0;
  span {
    background-color: #edd9db;
    padding: 6px 20px;
    color: #c0323c;
    border-radius: 20px;
    font-family: "g";
    font-size: 13px;
  }
}

.admin-info-value-enable {
  margin: 4px 0;
  span {
    background-color: #d8e6f4;
    padding: 6px 20px;
    color: $blue;
    border-radius: 20px;
    font-family: "g";
    font-size: 13px;
  }
}

.admin-info-value-disable {
  margin: 4px 0;
  span {
    background-color: #f0efef;
    padding: 6px 20px;
    color: #999999;
    border-radius: 20px;
    font-family: "g";
    font-size: 13px;
  }
}

.headline {
  border-radius: 5px;
  background-color: $tabBg;
  width: 40%;
  margin: 0 auto 40px;
  padding: 13px;
  h3 {
    font-family: "g";
    margin: 0;
    font-size: 20px;
    color: $label;
  }
}

.global-alart {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background-color: #0000005e;
  z-index: 99999;
  .inside-global-alart {
    max-width: 450px;
    right: 0;
    left: 0;
    position: absolute;
    margin: 100px auto;
    background-color: #fff;
    padding: 25px 50px;
    border-radius: 10px;
    p {
      font-size: 18px;
      font-family: "g";
      text-align: center;
      margin: 5px 0 30px;
    }
    button {
      @extend .btn-blue;
      width: 120px;
      height: 45px;
      font-size: 16px;
      font-weight: 600;
      &.submit {
        margin-right: 20px;
      }
    }
  }
}

.btn-blue {
  color: $blue !important;
  border: 1px solid $blue !important;
  background-color: transparent !important;
  padding: 3px 20px !important;
  font-family: "gL" !important;
  font-size: 13px;
  border-radius: 15px !important;
  text-decoration: none !important;
}

.btn-red {
  color: #f00 !important;
  border: 1px solid #f00 !important;
  background-color: transparent !important;
  padding: 3px 20px !important;
  font-family: "gL" !important;
  font-size: 13px;
  border-radius: 15px !important;
  text-decoration: none !important;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
  position: absolute;
  padding: 0 10px;
  font-size: 13px;
  border: 0px;
  bottom: -10px;
  border-radius: 25px;
  margin: 0px auto 0;
  left: 0;
  right: 0;
  width: 80%;
  text-align: center;
}
.input-text-wrapper {
  position: relative;
}

.history-change-bg {
  background-color: #437ff12b;
}

.history-single {
  padding: 50px;
  .admin-info-components {
    padding: 20px 25px;
    &.changed {
      background-color: #1f448a2e;
    }
  }
}

.table-design {
  table {
    border-radius: 10px;
    box-shadow: $tabBg 0px 0px 0px 1px;
    overflow: hidden;
    & > :not(:first-child) {
      border-top: 0;
    }
    thead {
      background-color: $tabBg;
      font-family: "gL";
      font-size: 14px;
      color: #9e9e9e;
      img {
        width: 30px;
      }
    }
    tbody {
      border: 0;
      tr {
        border-bottom: 1px solid $tabBg;
        font-weight: 700;
        td {
          font-family: "gL";
          border: 0;
          color: #4b5056;
          font-size: 15px;
          padding: 20px 0;
          &.admin-uid {
            word-break: break-word;
          }
        }
      }

      .search-view {
        @extend .btn-blue;
        &.delete {
          color: #c0323c !important;
          border: 1px solid #c0323c !important;
        }
      }
    }
  }
}

.selected-item {
  text-align: center;
}
