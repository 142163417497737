@import "../common/color.scss";
@import "../common/common.scss";

.motorbike {
  padding: 50px;

  .edit-btn {
    @extend .btn-blue;
    width: 100px;
    height: 35px;
    margin: 0;
    float: right;
    font-size: 13px !important;
  }

  .group-btn {
    ul {
      margin: 20px 0;
      padding: 0;

      li {
        display: inline-block;
        margin: 10px;

        button {
          @extend .btn-blue;
          font-weight: 700;
          margin: 0;
          float: right;
          font-size: 13px !important;
          padding: 9px 11px !important;
        }
      }
    }
  }
}

.motorbike-status {
  h5 {
    font-family: "gL";
    font-size: 14px;
    margin-top: 8px;
    text-align: center;
  }

  .approved-valid {
    background-color: $lightGreen;
    color: #fff;
    opacity: 0.75;
    padding: 6px 15px;
    border-radius: 20px;
    font-family: "g";
    font-size: 13px;
  }
  .approved-invalid {
    background-color: $lightGreen;
    color: #ffa7f5;
    opacity: 0.7;
    padding: 6px 15px;
    border-radius: 20px;
    font-family: "g";
    font-size: 13px;
  }
  .approved-invalid-expired {
    background-color: $lightRedPink;
    color: black;
    opacity: 0.7;
    padding: 6px 15px;
    border-radius: 20px;
    font-family: "g";
    font-size: 13px;
  }

  .processing {
    background-color: $lightBlue;
    color: blue;
    padding: 6px 15px;
    border-radius: 20px;
    font-family: "g";
    font-size: 13px;
  }

  .further-investigation {
    background-color: $lightOrange;
    color: orange;
    padding: 6px 15px;
    border-radius: 20px;
    font-family: "g";
    font-size: 13px;
  }

  .restricted {
    background-color: $lightGray;
    color: gray;
    padding: 6px 15px;
    border-radius: 20px;
    font-family: "g";
    font-size: 13px;
  }

  .rejected {
    background-color: $lightRed;
    color: red;
    padding: 6px 15px;
    border-radius: 20px;
    font-family: "g";
    font-size: 13px;
  }

  .removed {
    background-color: red;
    color: #fff;
    padding: 6px 15px;
    border-radius: 20px;
    font-family: "g";
    font-size: 13px;
  }

  .expired {
    background-color: $lightPurple;
    color: purple;
    padding: 6px 15px;
    border-radius: 20px;
    font-family: "g";
    font-size: 13px;
  }
}

.modal-popup-motorbike-status {
  iframe {
    width: 100%;
    height: 100%;

    img {
      height: 100%;
    }
  }

  font-family: "gL";

  .modal-dialog {
    max-width: calc(100% - 100px) !important;
  }

  .modal-header {
    padding: 10px 20px;
  }

  .title {
    text-align: left;
    font-size: 14px;
    padding: 15px 0 0px 0;
  }

  label {
    text-align: left;
    font-size: 14px;
  }

  input {
    font-weight: 700;
  }

  .modal-text {
    input {
      font-weight: 700;
      height: 45px;
      border-radius: 15px;
    }
  }

  .form-group {
    input {
      height: 45px;
      border-radius: 15px;
    }
  }

  .group-btn-reject {
    button {
      @extend .btn-blue;
      font-weight: 700;
      margin-top: 10px;
      height: 46px;

      &:disabled {
        opacity: 0.4;
      }

      &:first-child {
        width: 100px;
        margin-right: 5px;
      }

      &:nth-child(2) {
        width: calc(100% - 105px);
      }
    }
  }

  textarea {
    height: 80px;
    border-radius: 25px;
    width: 100%;
    padding: 10px;
    font-weight: 700;
    border: 1px solid #ced4da;
  }

  .group-btn-verify {
    text-align: center;
    margin: 20px 0;

    button {
      @extend .btn-blue;
      font-weight: 700;
      width: 100%;
      height: 46px;

      &:disabled {
        opacity: 0.4;
      }
    }
  }

  .modal-radio {
    label {
      margin-left: 5px;
    }
  }

  .form-group {
    select {
      padding: 0 10px;
      height: 45px;
      border-radius: 15px;
    }
  }

  .mg-top {
    margin-top: 10px;
  }

  .modal-footer {
    border: none;
  }
}

.modal-popup-motorbike-rejected-status {
  .modal-dialog {
    max-width: 600px;
    font-family: "gL";
    .modal-radio {
      label {
        margin-left: 5px;
      }
      input {
        margin: 10px 0;
      }
    }
    textarea {
      width: 100%;
      height: 200px;
      resize: none;
      border-radius: 5px;
      background-color: #fefefe;
      border: 1px solid #adadad;
      &.approve-popup-textarea {
        margin-top: 25px;
      }
    }
  }
}
