body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: "gBold";
  src: url("./fonts/Gotham.woff2") format("woff2"),
    url("./fonts/Gotham.woff") format("woff"),
    url("./fonts/Gotham.ttf") format("truetype"),
    url("./fonts/Gotham.svg#Gotham") format("svg");
}

@font-face {
  font-family: "g";
  src: url("./fonts/Gotham-Medium.woff2") format("woff2"),
    url("./fonts/Gotham-Medium.woff") format("woff"),
    url("./fonts/Gotham-Medium.ttf") format("truetype"),
    url("./fonts/Gotham-Medium.svg#Gotham") format("svg");

}

@font-face {
  font-family: "gL";
  src: url("./fonts/Gotham-Light.woff") format("woff"),
    url("./fonts/Gotham-Light.ttf") format("truetype"),
    url("./fonts/Gotham-Light.svg#Gotham") format("svg"),
    url("./fonts/Gotham-Light.otf") format("otf");
}

@font-face {
  font-family: "gR";
  src: url("./fonts/Gotham-Regular.woff") format("woff"),
    url("./fonts/Gotham-Regular.ttf") format("truetype"),
    url("./fonts/Gotham-Regular.svg#Gotham") format("svg"),
}

.modal-popup-custom-app-start{
  .btn{
    max-width: 200px;
    height: 42px;
    margin-top: 10px;
  }
}

*{
  font-family: "g";
}