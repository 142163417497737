@import "../common/color.scss";

.loginForm{
    font-family: "g" !important;
    .loginRow{
        height: 100vh;
        .loginImageHolder{
            height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;
            .loginImage{
                max-width: 300px;
                max-height: 300px;;
            }
        }

        .loginInput{
            height: 100vh;
            display: grid;
            align-content: center;
            max-width: 400px;
            margin: 0 auto;
            
        }
    }
}

.form-group{
    position: relative;
    .custom-error{
        position: absolute;
        padding: 0 10px;
        font-size: 13px;
        border: 0px;
        bottom: -10px;
        border-radius: 25px;
        margin: 0px auto 0;
        left: 0;
        right: 0;
        width: 80%;
        text-align: center;
    }
}

.form-control{
    padding: 18px 10px;
    outline: none !important;
    border-radius: 25px;
    outline-style: none !important;
    box-shadow: none !important;
}

h1{
    font-family: "gBold";
}

.btn-primary{
    background-color: $blue !important;
    border-color: $blue !important;
    font-size: 20px;
    display: block;
    width: 100%;
    margin-top: 45px;
    height: 62px;
    border-radius: 25px;
}

h6{
    text-align: left;
}