@import "../common/color.scss";
@import "../common/common.scss";

.filter-section {
  select {
    border-radius: 12px;
    height: 50px;
    padding: 0 10px;
    margin-bottom: 20px;
  }
  input {
    border-radius: 12px;
    height: 50px;
    padding: 0 10px;
    margin-bottom: 20px;
  }
}

.new-rider-steps {
  font-family: "gL";
  padding: 30px 25px;
  .header-title {
    h1 {
      margin: 0;
      font-size: 25px;
      font-family: "gBold";
      color: #14264a;
      margin: 40px 0;
    }
  }

  iframe {
    width: 100%;
    height: 100%;
    margin-top: 20px;
  }

  .certificate {
    min-height: 235px;
    margin: 25px 0;
    img {
      width: 100%;
    }
  }

  .input-radio-wrapper {
    text-align: left;
    margin-top: 15px;
    .title {
      text-align: left;
      margin-bottom: 5px;
    }
  }

  .avatar-image {
    img {
      margin: 20px auto 20px;
      width: 120px;
      height: 120px;
      border-radius: 5px;
    }
  }
  .input-text-wrapper {
    margin: 15px 0;
    text-align: left;
    label {
      margin-bottom: 5px;
    }
    input {
      font-family: "g";
      font-size: 19px;
      height: 50px;
    }
  }

  .group-btn-verify {
    button {
      width: 100%;
      height: 50px;
      margin: 40px 0;
      opacity: 1;
      @extend .btn-blue;
      &:disabled {
        opacity: 0.3;
      }
    }
  }
  .text-area-info {
    text-align: left;
    textarea {
      width: 100%;
      resize: none;
      height: 100px;
      padding: 10px;
      border-radius: 5px;
      margin-bottom: 20px;
    }
  }
  .group-btn-reject {
    button {
      @extend .btn-blue;
      width: 100%;
      height: 50px;
      margin: 20px 0 40px;
      opacity: 1;
      &:disabled {
        opacity: 0.3;
      }
    }
  }

  .steps {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: center;
    .step {
      &.number {
        width: 60px;
        height: 60px;
        background-color: #eee;
        border-radius: 30px;
        color: #fff;
        font-family: "gBold";
        font-size: 23px;
        padding: 10px;
      }
      &.line {
        width: 50px;
        height: 3px;
        background-color: #eee;
      }
      &.active {
        background-color: #14264a;
      }
    }
  }
}
.search-list {
  .search-view {
    margin: 0 5px;
  }
}
